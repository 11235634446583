module.exports = {
  URL_LOGIN_ADMIN: "admin/auth",
  URL_LOGIN_USER: "client/auth",
  URL_LOGIN_VENDOR: "vendor/auth",
  URL_LOGIN_DRIVER: "driver/auth",
  // URL_LOGOUT: "auth/logout",
  URL_PROFILE: "profile",
  URL_USER_PROFILE: "client/profile",
  URL_VENDOR_INFO: "vendor/info",
  URL_VENDOR_PROFILE: "vendor/profile",
  URL_VENDOR_LICENSE_FILE: "vendor/profile/license/file",
  URL_VENDOR_CHANGE_PASSWORD: "vendor/profile/change_password",
  URL_VENDOR_PASSWORD: "vendor/password",
  URL_VENDOR_FORGOT_PASSWORD: "vendor/forgot_password",
  URL_USER_SIGNUP_1: "client/signup",
  URL_USER_SIGNUP_CODE: "client/signupcode",
  URL_USER_SIGNUP_2: "client/signup2",
  URL_USER_PASSWORD: "client/password",
  URL_USER_FORGOT_PASSWORD: "client/forgot_password",
  URL_SIGNUP_VENDOR: "vendor/signup",
  URL_VENDOR_LIST: "vendor/list",
  URL_VENDOR_DRIVER_LIST: "vendor/driver/list",
  URL_VENDOR_CAR_LIST: "vendor/car/list",
  URL_VENDOR_APPROVE: "vendor/approve",
  URL_VENDOR_DRIVER: "vendor/driver",
  URL_VENDOR_CAR: "vendor/car",
  URL_SYSTEM_OPT: "system/opt",
  URL_ORDER_VENDOR_SEARCH: "client/vendor/search",
  URL_ORDER_CREATE: "client/order/create",
  URL_ORDER_CURRENT: "client/order/current",
  URL_VENDOR_ORDER_LIST: "vendor/order/list",
  URL_VENDOR_ORDER_INFO: "vendor/order/info",
  URL_VENDOR_ORDER_ACCEPT: "vendor/order/accept",
  URL_VENDOR_ORDER_HISTORY: "vendor/order/history",  
  URL_VENDOR_ORDER_CANCEL: "vendor/order/cancel",
  URL_VENDOR_ORDER_READED: "vendor/order/change/readed",
  URL_ORDER_PAY: "client/order/pay",
  URL_DRIVER_ORDERS: "driver/orders",
  URL_DRIVER_ORDER_PICKUP: "driver/order/pickup",
  URL_DRIVER_ORDER_COMPLETE: "driver/order/complete",
  URL_DRIVER_ORDER_HISTORY: "driver/order/history", 
  URL_DRIVER_ORDER_DETACH: "driver/order/detach",
  URL_CLIENT_ORDER_HISTORY: "client/order/history",
  URL_CLIENT_ORDER_PRESETS: "client/orderpresets",
  URL_CLIENT_ORDER_PRESET: "client/orderpreset",
  URL_ADMIN_ORDER_CURRENT: "order/current",
  URL_ADMIN_ORDER_HISTORY: "order/history",
  URL_ADMIN_CLIENT_LIST: "client/list",
  URL_ADMIN_CLIENT_INFO: "client/info",
  URL_ADMIN_ORDER_CANCEL: "order/cancel",
  URL_FEEDBACK: "feedback",
  URL_ORDER_DETACH: "client/order/detach",
  URL_PAYMENT_CREATE: "pay/create-payment-intent",
  URL_GET_FILE: "system/storage/get",
  URL_GET_STRIPE_ATTACH_LINK: "vendor/stripeacc",
};
