// Car types:
export const carTypes = [
  {
    id: 1,
    label: "Wheelchair",
    key: "costmt1",
    basepricekey: "baseprice1",
  },
  {
    id: 2,
    label: "Gurney",
    key: "costmt2",
    basepricekey: "baseprice2",
  },
];

// Escort types:
export const escortTypes = [
  {
    id: 0,
    label: "Select",
  },
  {
    id: 1,
    label: "Spouse",
  },
  {
    id: 2,
    label: "Son",
  },
  {
    id: 3,
    label: "Daughter",
  },
  {
    id: 4,
    label: "Other",
  },
];

// Gender types:
export const genderTypes = [
  {
    key: "",
    label: "Select gender",
  },
  {
    key: "M",
    label: "Male",
  },
  {
    key: "F",
    label: "Female",
  },
  {
    key: "N",
    label: "Non binary",
  },
];

// Vendor statuses: 0-new, 1-approved, 2-declined
export const vendorStatuses = [
  {
    id: 0,
    name: "new",
    label: "Waiting",
  },
  {
    id: 1,
    name: "approved",
    label: "Approved",
  },
  {
    id: 2,
    name: "declined",
    label: "Declined",
  },
];

// Order statuses
// 0-new,
// 1=accepted,
// 10-complete,
// 20-cancel,
// 21-reject,
// 22-expired,
// 25-cancel by client,
// 26-cancel vendor,
// 27-cancel admin
// 3 pickup,
// 4 work
export const orderStatuses = [
  {
    id: 0,
    name: "new",
    label: "New",
    class: "ribbon-purple",
  },
  {
    id: 1,
    name: "accepted",
    label: "Accepted",
    class: "ribbon-warning",
  },
  {
    id: 10,
    name: "complete",
    label: "Complete",
    class: "ribbon-info",
  },
  {
    id: 20, // cancel before payed
    name: "cancel",
    label: "Canceled",
    class: "ribbon-dark",
  },
  {
    id: 21,
    name: "reject",
    label: "Rejected",
    class: "ribbon-danger",
  },
  {
    id: 22,
    name: "expired",
    label: "Expired",
    class: "ribbon-secondary",
  },
  // canceled payed order: 25-cancel by client, 26-cancel vendor, 27-cancel admin
  {
    id: 25,
    name: "cancel_client",
    label: "Canceled by client",
    class: "ribbon-secondary",
  },
  {
    id: 26,
    name: "cancel_vendor",
    label: "Canceled by vendor",
    class: "ribbon-secondary",
  },
  {
    id: 27,
    name: "cancel_admin",
    label: "Canceled by admin",
    class: "ribbon-secondary",
  },
  {
    id: 3,
    name: "pick_up",
    label: "Pick up",
    class: "ribbon-success",
  },
  {
    id: 4,
    name: "work",
    label: "In progress",
    class: "ribbon-success",
  },
];

// Comment to for fix deprecated utc_offset error and economy (more data more price)
// https://developers.google.com/maps/documentation/places/web-service/details
// Can use: geometry.location
export const mapFields = [
  // "ALL" // All fields
  "address_components",
  // "adr_address",
  // "business_status",
  "formatted_address",
  // "formatted_phone_number",
  // "geometry",
  "geometry.location",
  // "icon",
  // "icon_background_color",
  // "icon_mask_base_uri",
  // "international_phone_number",
  // "name",
  // "opening_hours",
  // "permanently_closed", // deprecated
  // "photos",
  // "place_id",
  // "plus_code",
  // "price_level",
  // "rating",
  // "reference", // deprecated
  // "reviews",
  // "scope", // deprecated
  // "types",
  // "url",
  // "user_ratings_total",
  // "utc_offset", // deprecated
  // "vicinity",
  // "website",
  "utc_offset_minutes",
];

export const timeline = [
  {
    id: 1,
    name: "12pm",
    time: "12:00",
    f: "1200",
  },
  {
    id: 2,
    name: "1pm",
    time: "13:00",
    f: "1300",
  },
  {
    id: 3,
    name: "2pm",
    time: "14:00",
    f: "1400",
  },
  {
    id: 4,
    name: "3pm",
    time: "15:00",
    f: "1500",
  },
  {
    id: 5,
    name: "4pm",
    time: "16:00",
    f: "1600",
  },
  {
    id: 6,
    name: "5pm",
    time: "17:00",
    f: "1700",
  },
  {
    id: 7,
    name: "6pm",
    time: "18:00",
    f: "1800",
  },
  {
    id: 8,
    name: "7pm",
    time: "19:00",
    f: "1900",
  },
  {
    id: 9,
    name: "8pm",
    time: "20:00",
    f: "2000",
  },
  {
    id: 10,
    name: "9pm",
    time: "21:00",
    f: "2100",
  },
  {
    id: 11,
    name: "10pm",
    time: "22:00",
    f: "2200",
  },
  {
    id: 12,
    name: "11pm",
    time: "23:00",
    f: "2300",
  },
  {
    id: 13,
    name: "12am",
    time: "00:00",
    f: "0",
  },
  {
    id: 14,
    name: "1am",
    time: "01:00",
    f: "100",
  },
  {
    id: 15,
    name: "2am",
    time: "02:00",
    f: "200",
  },
  {
    id: 16,
    name: "3am",
    time: "03:00",
    f: "300",
  },
  {
    id: 17,
    name: "4am",
    time: "04:00",
    f: "400",
  },
  {
    id: 18,
    name: "5am",
    time: "05:00",
    f: "500",
  },
  {
    id: 19,
    name: "6am",
    time: "06:00",
    f: "600",
  },
  {
    id: 20,
    name: "7am",
    time: "07:00",
    f: "700",
  },
  {
    id: 21,
    name: "8am",
    time: "08:00",
    f: "800",
  },
  {
    id: 22,
    name: "9am",
    time: "09:00",
    f: "900",
  },
  {
    id: 23,
    name: "10am",
    time: "10:00",
    f: "1000",
  },
  {
    id: 24,
    name: "11am",
    time: "11:00",
    f: "1100",
  },
  {
    id: 25,
    name: "12pm",
    time: "12:00",
    f: "1200",
  },
];
